import React, { useEffect, useState } from "react";
import init, { add, add_list } from "wasm-rust";
import { AddNewItem } from "./AddNewItem";
import { Column } from "./Column";
import { AppContainer } from "./styles";
import { useAppState } from "./state/AppStateContext";
import { addList } from "./state/action";

export const App = () => {
  const [ans, setAns] = useState(0);
  useEffect(() => {
    init().then(() => {
      setAns(add(1, 1));
      console.log(ans);
    });
  }, [ans]);
  const { lists, dispatch } = useAppState();
  return (
    <AppContainer>
      {lists.map((l) => (
        <Column text={l.title} id={l.id} key={l.id}></Column>
      ))}
      <AddNewItem
        toggleButtonText="+ Add another list"
        onAdd={(t) => {
          if (t && t.length > 0) {
            // TODO: 副作用的处理
            dispatch(addList(t));
            add_list(t);
          }
        }}
      />
    </AppContainer>
  );
};
