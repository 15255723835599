// 全局 app 状态 Store

import {
  Dispatch,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
} from "react";
import { AppState, List, Task, appStateReducer } from "./appStateReducer";
import { useImmerReducer } from "use-immer";
import { Action } from "./action";

// TODO: 用于测试的初始化数据, 实际应在启动后从底层读取出来.
const appData: AppState = {
  lists: [
    {
      id: "0",
      title: "To Do",
      tasks: [
        {
          id: "c0",
          text: "hello",
        },
      ],
    },
    {
      id: "1",
      title: "Doing",
      tasks: [
        {
          id: "c1",
          text: "正在进行",
        },
      ],
    },
    {
      id: "2",
      title: "Done",
      tasks: [
        {
          id: "c3",
          text: "完成了!!",
        },
      ],
    },
  ],
};

// Context 参数
type AppStateContextProps = {
  lists: List[];
  getTasksByListId(id: string): Task[];
  dispatch: Dispatch<Action>;
};

// 可用于全局的 Context, 外界使用时不直接用, 通过 Provider 会更容易注入
const AppStateContext = createContext<AppStateContextProps>(
  {} as AppStateContextProps // 如果父级没有Provider 节点, 提供默认数据, 这里一个空的结构即可
);

// 用于树上的状态提供.
export const AppStateProvider: FC<PropsWithChildren> = ({ children }) => {
  // TODO: 需要在合适的时机从底层拿初始化的数据
  const [state, dispatch] = useImmerReducer(appStateReducer, appData);
  const { lists } = state;
  const getTasksByListId = (id: string) => {
    return lists.find((list) => list.id === id)?.tasks || [];
  };
  return (
    <AppStateContext.Provider value={{ lists, getTasksByListId, dispatch }}>
      {children}
    </AppStateContext.Provider>
  );
};

// 自定义 hook, 方便使用, 这个 hook 的作用是在调用后返回 app state 对象
export const useAppState = () => {
  return useContext(AppStateContext);
};
