import { add_task } from "wasm-rust";
import { AddNewItem } from "./AddNewItem";
import { Card } from "./Card";
import { ColumnContainer, ColumnTitle } from "./styles";
import { useAppState } from "./state/AppStateContext";
import { addTask } from "./state/action";

type ColumnProps = {
  text: string;
  id: string;
};

export const Column = ({ text, id }: ColumnProps) => {
  const { getTasksByListId, dispatch } = useAppState();
  const tasks = getTasksByListId(id);
  return (
    <ColumnContainer>
      <ColumnTitle>{text}</ColumnTitle>
      {tasks.map((t) => (
        <Card key={t.id} text={t.text} id={t.id}></Card>
      ))}
      <AddNewItem
        toggleButtonText="+ Add another card"
        onAdd={(t) => {
          if (t && t.length > 0) {
            dispatch(addTask(t, id));
            add_task(t, 0);
          }
        }}
        dark
      />
    </ColumnContainer>
  );
};
