import { nanoid } from "nanoid";
import { Action } from "./action";

export type Task = {
  id: string;
  text: string;
};

export type List = {
  id: string;
  title: string;
  tasks: Task[];
};

export type AppState = {
  lists: List[];
};

export const appStateReducer = (
  draft: AppState,
  action: Action
): AppState | void => {
  switch (action.type) {
    case "ADD_LIST": {
      draft.lists.push({
        id: nanoid(),
        title: action.payload,
        tasks: [],
      });
      break;
    }
    case "ADD_TASK": {
      const targetListIndex = draft.lists.findIndex(
        (l) => l.id === action.payload.listId
      );
      draft.lists[targetListIndex].tasks.push({
        id: nanoid(),
        text: action.payload.text,
      });
    }
  }
};
